export const sentences = [
    ["Ohù! ma runni mi purtasti?"],
    ["In discoteca, addisìati a saluti, addiviiaittiti!", "guarda che bella gioventù..."],
    ["Ma chiddi comu parranu?"],
    ["HO TTALAFONATO A IRANA, E MI HA PPOSTEGGIATO QUESTA STRONZA"],
    ["E poi sono avvilito...", "Stamattina non potevo trovare i jeans e gli ho fatto il casino a mia madre"],
    ["Che schifo, non ne posso più, non ne posso", "SONO ANTIQUATI"],
    ["Hai visto che bei discorsi di caffè? Addivittìììamuni!"],
    ["Andiamo in pista a bballari", "e nn′annacam' anticchia puru nuàtri"],
    ["Assira vi-vi-vipp′assai"],
    ["E accussì accuminciaru i guai"],
    ["Picchì 'mmiscavi gin sambuca coca", "Whisky birra lemon soda"],
    ["E a testa ormai l'avìa tutta partuta", "Ordinai n′azzusa"],
    ["E mi spilavan′i 'ravanzi", "Tanta gente ch′i guanti"],
    ["Io i scanciavu pi ppuntunìeri"],
    ["Erano invece cammarìeri"],
    ["Mi si 'mpajò un′i ravanzi", "U cuntu iera bellu pisanti"],
    ["S'accullaru Francu, Gino e Pippino".toUpperCase()],
    ["Emigrat′i Brucculino"],
    ["Hannu ddà 'na pizzeria", "Stannu bbuon'a facci mia"],
    ["C′era ninò-ninò-ninò Nino u turcu".toUpperCase()],
    ["Ca tuinnò a stari ′o Bbuiggu"],
    ["Fu paittùtu pi dec'anni"],
    ["S′arricugghìu senza mutanni"],
    ["E allora su-su", "su-cantiam"],
    ["Questo rap palermitan"],
    ["Su-su<br>su-cantiam", "Questo rap palermitan"],
    ["Può sembrare alquanto strano", "Va di moda avusitano"],
    ["È uno slang conosciuto"],
    ["Minchia quantu è cuinnuto!".toUpperCase()],
    ["S'un ti va, un va, così un va"],
    ["Schì e schì e pass′in là"],
    ["S'un ti va, un va, cos′un va"],
    ["Schì e schì e pass'in là"],
    ["E pigghià chistu ri ccà", "Va cuntaccill'a ttò mà"],
    ["Sei nato scafazzato non c′è niente da fà"],
    ["Questo stress non ha più ness", "siamo tutti PER LA PRESS"],
    ["I Mondell′a Rrumagnùalu", "semu com'i pisc′i bbrùaru"],
    ["Ci su ppur'i Ficarazzi", "press'i giebbia senza cazzi"],
    ["E s′acchiani a Bbeddulampu", "l'appressati unn′hannu scampu"],
    ["Lì dall'alto puoi guardare", "quanta press c'è r′iccare..."],
    ["PRESS-PRESS<br>PRESS-DANCE"],
    ["SIAMO TUTTI<br>PER LA PRESS"],
    ["Vado in gruppo in discoteca", "ogni sera, che me frega?"],
    ["Scendo in pista, stan ballando", "Sugar Hill stanno imitando"],
    ["Mc Hammer canta Pray", "Ri scoppiati ci nnè assei"],
    ["Ch′i capiddi a scussunieddu", "C'aricchini ma quant′è bieddu?"],
    ["Ci nn'è n avutru curiusu", "A mmia mi pari tuttu fusu"],
    ["Sdivacatu nn′o divano", "N avutru elemento strano"],
    ["Minigonne e cul'i fuora", "Chista è a moda chi cc′è uora"],
    ["Ch'i blue-jeans tutti spardati"],
    ["I cchiù nnuovi su arripizzati"],
    ["Spardi ri ccà e poi spardi ri ddà", "Ma ditemi voi chi ccosa sentono fà?"],
    ["Ti fannu riscursi, ti stuoiccin'i mussi", "C′u l′uocchi ri sarda e quacchi cùaippu ri tussi"],
    ["No-no-no questo non va"],
    ["Taliàtivi 'nto specchio e sputativi ′nta fà!"],
    ["Gioventù-ventù piena di stress"],
    ["Sono tutti<br>PER LA PRESS"],
    ["No-mi-nà ni-nò-nò, ni-nà", "Che gioventù bruciata 'sta società"],
    ["Non c′è più scampo e mancu via d'uscita", "C′è un bellu canile proprio rintra 'a Favorita"],
    ["No mi-nò-mi-nò mi-nà"],
    ["Ma iti 'o Menz′aranciu e ghiccativi ri ddà"],
    ["No ni-nò, no no ni-nò ninnò", "L′atalà ddo pappà de pà de pà"],
    ["Dididì de pappà, de pà de pà", "Vi dico bello chiaro SCHI E SCHI E PASS'IN Là"],
    ["Ma quant′è bella sta litania?", "Vi giuro e vi spergiuro veni u cuari pur'a mmia"],
    ["Questo rap mi sta esaltando", "PRESS-DANCE io vi sto cantando"],
    ["No ni-nò ni nnò ni-nò"],
    ["Scrivu sìatti<br>e poittu O", "Calu quattru e moltiplico pìi nove"],
    ["🅸🆄 🆃′🅰🅽🅵🅸🅻🆄 🅴 🆃🆄 🆄🅽 🆃🅸 🅼🆄🅾🆅🅴"],
    ["Ma belli riscursi come se nuddu ci fussi", "Ancora ancora ancora sempre di più"],
    ["E questo ritmo assurdo", "Addivintavu un po' sùiddu"],
    ["Però mi prende e mi fa cantar"],
    ["UÁN TU FRIII FOR", "Balla balla balla finchè puoi"],
    ["Ma su tutti paittùti, c′è da restare alluccuti", "Hannu l'uocchi apìeitti ma su addumisciuti"],
    ["UÁN TU FRIII FOR", "Balla balla balla che PRESS SIETE VOI!"],
    ["Assira vi-vi-vipp'assai"],
    ["E r′accussì accuminciar′i guai"],
    ["Perché ho scoperto che nel mondo non c'è più ness"],
    ["Siamo tutti<br>PER LA PRESS"],
    ["Siamo stati contaminati", "R′una poch'i i sdisànurati"],
    ["Chi a ffuoizza ri fari buiddìellu", "M′a puittavu u manicu r'umbrellu"],
    ["Allora su-ca, su-ca, su-cantiam"],
    ["Questo rap palermitan"],
    ["Può sembrare alquanto osceno", "Va di moda avusiteno"],
    ["È uno slang conosciuto"],
    ["Minchia quantu è cuinnutu!".toUpperCase()],
    ["", ""],
    ["Minchia cavuru... ancuora am′a bballari?"],
    ["Basta, 'emunn'assittàri ca sugnu stancu"],
    ["Leva rimini..."],
    ["", ""],
    ["Ohu viri ca cc′hai a cinnìera apìeitta"],
    ["Eh"], ["E à pigghiari nìanti prima ca chiùju?"],
    ["", ""],
    ["Giovanni Alamia", "Tony Sperandeo"],
    ["", ""],
    ["", ""]
]
