import ReactGA from 'react-ga'
import he from 'he'
import img from './assets/images/palermo-1.webp'
import {sentences} from "./sentences";
import './App.css';
import React, {useEffect, useState} from "react";
import useQueryParam from './useQueryParam';

ReactGA.initialize('UA-110162028-2')
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
    const floorMod = (a,n) => {
        // Type casting is necessary
        // as (int) / (int) will give
        // int result, i.e. -3 / 2
        // will give -1 and not -1.5
        let q = Math.floor(Math.floor(a / n));

        // Return the resultant remainder
        return a - n * q;
    }

    const [s, setS] = useQueryParam('s', '0')
    const [locked, setLocked] = useState(false)


    const [index, setIndex] = useState(isNaN(parseInt(s))?0:parseInt(s))
    const [sentence, setSentence] = useState([])
    const [className, setClassName] = useState("visible")

    useEffect(() => {
        setS(index.toString())
        setSentence(sentences[floorMod(index, sentences.length)]);
        const timeout = setTimeout(() => {
            setClassName('visible');
            clearTimeout(timeout)
        },0)
    }, [index])

    useEffect(()=>{
        if(!isNaN(parseInt(s)) && parseInt(s)!==0 ){
            setLocked(true);
            setIndex(parseInt(s));
        }
        console.log('   ___   ___   ___                                              \n' +
            '  / _ \\ / _ \\ / _ \\   /\\                                        \n' +
            ' | (_) | | | | | | | /  \\   _ __  _ __                          \n' +
            '  > _ <| | | | | | |/ /\\ \\ | \'_ \\| \'_ \\                         \n' +
            ' | (_) | |_| | |_| / ____ \\| |_) | |_) |                        \n' +
            '  \\___/ \\___/ \\___/_/    \\_\\ .__/| .__/                         \n' +
            '                           | |   | |                            \n' +
            '                           |_|   |_|                     \n')

    },[])
    useEffect(() => {

        const interval = setInterval(() => {
            if(locked){
                return;
            }
            const updateIndex = (timeout1) => {
                setClassName('hidden')
                if(timeout1)
                    clearTimeout(timeout1)
                const timeout = setTimeout(() => {
                    setIndex(floorMod(index + 1, sentences.length))
                    clearTimeout(timeout)
                }, 300)
            }
            if(sentences[index].length>1) {
                const timeout1 = setTimeout(() => {
                    updateIndex(timeout1)
                }, 2000)
            }else{
                updateIndex()
            }
            }, 3000)




        return () => clearInterval(interval);

    })

    const touchEvent = (e) => {
        if(e.changedTouches.length === 1) {

            setClassName('hidden')
            const timeout = setTimeout(() => {
                setLocked(false);
                setIndex(floorMod(index + ((e.changedTouches[0].clientX >= e.target.offsetWidth/2)?1:-1), sentences.length))
                clearTimeout(timeout)
            }, 300)
        }else{
            setLocked(true);
        }
    }
    const clickEvent = (e) => {
        if(e.clientX) {
            setClassName('hidden')
            const timeout = setTimeout(() => {
                setLocked(false);
                setIndex(floorMod(index + ((e.clientX >= e.target.offsetWidth/2)?1:-1), sentences.length))
                clearTimeout(timeout)
            }, 300)
        }else{
            setLocked(true);
        }
    }

    const justOne = sentence.length === 1
  return (
    <div className="App" style={{
        backgroundImage: 'linear-gradient(pink, black), url('+img+')',
        backgroundSize: 'cover',
        backgroundPosition: "bottom right",
        backgroundBlendMode: "difference, hue",
        height: '100vh',
    }} onTouchEnd={touchEvent} onClick={clickEvent}>
        <header className="app-header">
          <h1><a href="/">perla.press</a></h1>
        </header>
        <main>
            {sentence[0] !== "" &&
            <div>
                {/*<img src={logo} className="App-logo" alt="logo" />*/}
                <h2 className={'text-style-' + 1 + ' ' + className} dangerouslySetInnerHTML={{__html: sentence[0]}}>
            
                </h2>
                {!justOne &&
                    <hr style={{width: '30%'}}/>
                }
                {!justOne &&
                    <h2 className={'text-style-' + 1 + ' ' + className} dangerouslySetInnerHTML={{__html: sentence[1]}}>
    
                    </h2>
                }
            </div>
            }

        </main>
        <footer><h3>© 2022 - Franco Coiffeur</h3></footer>
    </div>
  );
}

export default App;
